import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { SelectionOption } from "helpers/models/auth_models";
import * as Yup from "yup";
import {
  getCountries,
  getStates,
  getCity,
  getCompanyDomain,
  createCompany,
} from "services/authRequests";
import InputTextField from "components/forms/InputTextField";
import SearchableDropdown from "components/forms/SearchableDropdown";
import FormButton from "components/forms/FormButton";
import Button from "components/base/button/Button";
import logo from "assets/images/NiyantrasLogoWithName.webp";
import { useAuth } from "hooks‬/Auth";
import { useTranslation } from "react-i18next";
import {
  useAuthValidationRules,
  useDropdownValidationRules,
} from "helpers/validation-schema/AuthValidationRules";
import { ERROR_STATUS_KEY } from "constant/errorCode";
import { signOut } from "helpers/AuthHelpers";

const InitialCompanyDetailsStepper: React.FC = () => {
  const { t } = useTranslation();
  const validationRules = useAuthValidationRules();
  const { companyDetailFormDashboarding, currentCompany } = useAuth();
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState<SelectionOption[]>([]);
  const [states, setStates] = useState<SelectionOption[]>([]);
  const [domain, setDomain] = useState<SelectionOption[]>([]);
  const [city, setCity] = useState<SelectionOption[]>([]);
  const [activeStep, setActiveStep] = useState(0);

  const initialValues = {
    name: "",
    address1: "",
    address2: "",
    email: "",
    companyDomainGuid: "",
    companyWebsiteUrl: "",
    contactNumber: "",
    websiteUrl: "",
    selectedCountry: null,
    selectedState: null,
    selectedCity: null,
    pinCode: "",
    isReseller: "",
    countryName: "",
    countryGuid: "",
    stateGuid: "",
    cityGuid: null,
    cityName: "",
    companyDetailsGuid: "",
  };

  const fetchCountries = async () => {
    try {
      await getCountries()
        .then((res: any) => {
          setCountry(
            res.data.data.map((items: any) => ({
              label: items.displayName,
              value: items.guid,
            }))
          );
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error(error);
    }
  };
  const stateHandler = async (guid: string) => {
    formik.setFieldValue("stateGuid", "");
    formik.setFieldValue("cityGuid", "");
    setStates([]);
    setCity([]);
    try {
      await getStates(guid)
        .then((res: any) => {
          setStates(
            res.data.data.map((items: any) => ({
              label: items.displayName,
              value: items.guid,
            }))
          );
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error(error);
    }
  };
  const cityHandler = async (guid: string) => {
    formik.setFieldValue("cityGuid", "");
    setCity([]);
    try {
      await getCity(guid)
        .then((res: any) => {
          setCity(
            res.data.data.map((items: any) => ({
              label: items.displayName,
              value: items.guid,
            }))
          );
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  const comapnyDomainHandler = async () => {
    try {
      await getCompanyDomain()
        .then((response: any) => {
          const data = response.data.data.map((items: any) => ({
            label: items.displayName,
            value: items.companyDomainGuid,
          }));
          setDomain(data);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  useEffect(() => {
    fetchCountries();
    comapnyDomainHandler();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    formik.setValues({
      ...initialValues,
      name: currentCompany.name,
      companyDetailsGuid: currentCompany.companyDetailsGuid,
    });
    // eslint-disable-next-line
  }, [currentCompany]);

  const companyDetailSchema = Yup.object().shape({
    name: validationRules.nameValidationSchema,
    email: validationRules.emailValidationSchema,
    contactNumber: validationRules.contactNumberValidationSchema,
    companyDomainGuid: useDropdownValidationRules(domain),
    address1: validationRules.address1ValidationSchema,
    countryGuid: useDropdownValidationRules(country),
    stateGuid: useDropdownValidationRules(states),
    cityGuid: useDropdownValidationRules(city),
    pinCode: validationRules.pinCodeValidationSchema,
  });

  const formik = useFormik({
    initialValues,
    validationSchema: companyDetailSchema,
    onSubmit: async (values) => {
      const {
        address1,
        address2,
        cityGuid,
        companyDetailsGuid,
        companyDomainGuid,
        companyWebsiteUrl,
        contactNumber,
        email,
        isReseller,
        name,
        pinCode,
        websiteUrl,
      } = values;
      setLoading(true);
      try {
        await createCompany({
          address1,
          address2,
          cityGuid,
          companyDetailsGuid,
          companyDomainGuid,
          companyWebsiteUrl,
          contactNumber,
          companyEmail: email,
          isReseller,
          name,
          pinCode,
          websiteUrl,
        })
          .then((response: any) => {
            if (response.data.status === ERROR_STATUS_KEY) {
              setLoading(false);
            } else {
              companyDetailFormDashboarding();
            }
          })
          .catch(() => setLoading(false));
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    },
  });

  const handleNext = async () => {
    const fieldsToValidate = [
      "name",
      "email",
      "contactNumber",
      "companyDomainGuid",
    ];
    let isValid = true;
    for (const field of fieldsToValidate) {
      try {
        await formik.validateField(field);
        formik.setFieldTouched(field, true);
        if (
          formik.errors.email ||
          formik.errors.name ||
          formik.errors.contactNumber ||
          formik.errors.companyDomainGuid
        ) {
          isValid = false;
        }
      } catch (error) {
        formik.setFieldTouched(field, true);
        isValid = false;
      }
    }
    if (isValid) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const renderFormStep = () => {
    switch (activeStep) {
      case 0:
        return (
          <form onSubmit={formik.handleSubmit}>
            <InputTextField
              label={t("COMPANY.NAME")}
              labelClass="required"
              type="text"
              placeholder={t("COMPANY.NAME")}
              id="name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <InputTextField
              label={t("COMPANY") + " " + t("EMAIL")}
              labelClass="required"
              type="text"
              placeholder="Email"
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <InputTextField
              label={t("CONTACT.NUMBER")}
              labelClass="required"
              type="text"
              placeholder={t("CONTACT.NUMBER")}
              id="contactNumber"
              name="contactNumber"
              value={formik.values.contactNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.contactNumber &&
                Boolean(formik.errors.contactNumber)
              }
              helperText={
                formik.touched.contactNumber && formik.errors.contactNumber
              }
            />
            <SearchableDropdown
              label={t("COMPANY.DOMAIN")}
              labelClass="required"
              name="companyDomainGuid"
              placeholder={t("COMPANY.DOMAIN")}
              value={formik.values.companyDomainGuid || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              selectionOptions={domain}
              error={
                formik.touched.companyDomainGuid &&
                Boolean(formik.errors.companyDomainGuid)
              }
              helperText={
                formik.touched.companyDomainGuid &&
                formik.errors.companyDomainGuid
              }
            />

            <div className="row">
              <div className="col-4 p-1 d-grid my-4">
                <Button type="button" onClick={handleNext}>
                  {t("NEXT")}
                </Button>
              </div>
              <div className="col-8 p-1 d-grid justify-content-end ">
                <div className="text-center d-flex justify-content-center align-items-center">
                  <div
                    className="text-primary   fs-3"
                    style={{ height: "20px" }}
                  >
                    {t("ONE.BY.TWO")}
                  </div>
                </div>
              </div>
            </div>
          </form>
        );
      case 1:
        return (
          <form onSubmit={formik.handleSubmit}>
            <InputTextField
              label={t("ADDRESS1")}
              labelClass="required"
              type="text"
              placeholder={t("ADDRESS1")}
              id="address1"
              name="address1"
              value={formik.values.address1}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.address1 && Boolean(formik.errors.address1)}
              helperText={formik.touched.address1 && formik.errors.address1}
            />
            <SearchableDropdown
              label={t("COUNTRY")}
              labelClass="required"
              placeholder={t("COUNTRY")}
              name="countryGuid"
              value={formik.values.countryGuid || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              selectionOptions={country}
              getSelectedOption={stateHandler}
              error={
                formik.touched.countryGuid && Boolean(formik.errors.countryGuid)
              }
              helperText={
                formik.touched.countryGuid && formik.errors.countryGuid
              }
            />{" "}
            <SearchableDropdown
              label={t("STATE")}
              labelClass="required"
              getSelectedOption={cityHandler}
              placeholder={t("STATE")}
              name="stateGuid"
              value={formik.values.stateGuid || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              selectionOptions={states}
              error={
                formik.touched.stateGuid && Boolean(formik.errors.stateGuid)
              }
              helperText={formik.touched.stateGuid && formik.errors.stateGuid}
            />
            <div className="row">
              <div className="col-6">
                <SearchableDropdown
                  label={t("CITY")}
                  labelClass="required"
                  placeholder={t("CITY")}
                  name="cityGuid"
                  value={formik.values.cityGuid ?? ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  selectionOptions={city}
                  error={
                    formik.touched.cityGuid && Boolean(formik.errors.cityGuid)
                  }
                  helperText={formik.touched.cityGuid && formik.errors.cityGuid}
                />
              </div>
              <div className="col-6">
                <InputTextField
                  label={t("PINCODE")}
                  labelClass="required"
                  type="text"
                  placeholder={t("PINCODE")}
                  id="pinCode"
                  name="pinCode"
                  value={formik.values.pinCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.pinCode && Boolean(formik.errors.pinCode)
                  }
                  helperText={formik.touched.pinCode && formik.errors.pinCode}
                />
              </div>
            </div>
            <div className="row justify-content-between">
              <div className="col-8 d-flex justify-content-start m-0  ">
                <div className="row w-100 flex-wrap">
                  <div className="col-6 col-md-4  d-flex align-items-center m-0">
                    <div className="d-flex align-items-center mx-0">
                      <Button
                        type="button"
                        variant="outline"
                        onClick={handleBack}
                      >
                        {t("BACK")}
                      </Button>
                    </div>
                  </div>{" "}
                  <div className="col-6 col-md-8 d-flex align-items-center justify-content-start">
                    <div className="d-flex align-items-center justify-content-center   ">
                      <FormButton
                        btnLabel={t("SUBMIT")}
                        classes="btn-lg "
                        formik={formik}
                        isBtnDisabled={formik.isSubmitting || !formik.isValid}
                        loading={loading}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4 p-1 d-grid my-4">
                <div className="d-flex align-items-center justify-content-end  mx-2">
                  <label className="text-primary   fs-3">
                    {" "}
                    {t("TWO.BY.TWO")}
                  </label>
                </div>
              </div>
            </div>
          </form>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
        <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
          <a href="https://mail.google.com/" className="mb-12">
            <img alt="Logo" src={logo} className="theme-dark-show h-45px" />
            <img
              alt="Logo"
              src={logo}
              className="theme-light-show h-45px"
            ></img>
          </a>
          <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
            <div className="text-center mb-11">
              <h1 className="text-dark fw-bolder mb-3">
                {t("COMPANY.DETAILS")}
              </h1>
              <div className="text-gray-500 fw-semibold fs-6">
                {t("messages:FILL.YOUR.COMPANY.DETAILS")}
              </div>
            </div>
            {renderFormStep()}
          </div>
          <div className="my-4">
            {t("messages:LOGIN.WITH.ANOTHER.CREDENTIAL")}{" "}
            <span
              className="link-primary pointer fw-bold"
              onKeyDown={() => {
                signOut();
              }}
              onClick={() => {
                signOut();
              }}
            >
              {t("LOGOUT")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InitialCompanyDetailsStepper;
