import {
  ENDPOINT_ALL_PRODUCTS,
  ENDPOINT_COMPANY_BRANCH,
  ENDPOINT_COMPANY_LEGAL_DETAILS,
  ENDPOINT_COMPANY_PREFERENCES,
  ENDPOINT_COMPANY_PREFERENCES_SYSTEM_MODE,
  ENDPOINT_CREATE_REBRANDING,
  ENDPOINT_LEGAL_DETAILS_FIELD,
  ENDPOINT_OFFERED_SERVICES,
  ENDPOINT_PRODUCTS,
  ENDPOINT_REBRANDING,
  ENDPOINT_SERVICES,
} from "constant/apiConstants";
import apiInterceptor from "./apiInterceptor";
interface OfferedServicesType {
  isReseller: string;
  servicesList: {
    serviceGuid: string;
    status: string;
  }[];
}

export function getProducts() {
  return apiInterceptor.get(`${ENDPOINT_PRODUCTS}`);
}
export function getAllProducts() {
  return apiInterceptor.get(`${ENDPOINT_ALL_PRODUCTS}`);
}
export function getServices(guid: string) {
  return apiInterceptor.get(`${ENDPOINT_SERVICES}/${guid}`);
}
export function getOfferedServices() {
  return apiInterceptor.get(`${ENDPOINT_OFFERED_SERVICES}`);
}
export function updateOfferedServices(data: OfferedServicesType) {
  return apiInterceptor.put(ENDPOINT_OFFERED_SERVICES, data);
}

export function getCompanyLegalDetailsField(guid: string) {
  return apiInterceptor.get(`${ENDPOINT_LEGAL_DETAILS_FIELD}/${guid}`);
}

export function getCompanyLegalDetailsValue() {
  return apiInterceptor.get(`${ENDPOINT_COMPANY_LEGAL_DETAILS}`);
}

export function putCompanyLegalDetailsValue(json: any) {
  return apiInterceptor.put(`${ENDPOINT_COMPANY_LEGAL_DETAILS}`, json);
}

export function getCompanyPreferences() {
  return apiInterceptor.get(`${ENDPOINT_COMPANY_PREFERENCES}`);
}

export function putCompanyPreferences(json: any) {
  return apiInterceptor.put(`${ENDPOINT_COMPANY_PREFERENCES}`, json);
}
export function updateSystemModeCompanyPreferences(mode: string) {
  return apiInterceptor.put(`${ENDPOINT_COMPANY_PREFERENCES_SYSTEM_MODE}`, {
    systemMode: mode,
  });
}
export function createCompanyBranch(json: any) {
  return apiInterceptor.post(`${ENDPOINT_COMPANY_BRANCH}`, json);
}

export function getCompanyBranch() {
  return apiInterceptor.get(ENDPOINT_COMPANY_BRANCH);
}

export function deleteCompanyBranch(company_branch_guid: string) {
  return apiInterceptor.delete(
    `${ENDPOINT_COMPANY_BRANCH}/${company_branch_guid}`
  );
}

export function updateCompanyBranch(json: any, company_branch_guid: string) {
  return apiInterceptor.put(
    `${ENDPOINT_COMPANY_BRANCH}/${company_branch_guid}`,
    json
  );
}

export function getCompanyRebranding() {
  return apiInterceptor.get(ENDPOINT_REBRANDING);
}

export function updateCompanyRebranding(formDta: any) {
  return apiInterceptor.post(ENDPOINT_CREATE_REBRANDING, formDta);
}
