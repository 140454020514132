import languages from "constant/json/languages.json";
import timezones from "constant/json/timezones.json";
import { SelectionOption } from "helpers/models/auth_models";

export const statusGetter = (status: string) => {
  let setStatus;
  if (status === "Active") {
    setStatus = "A";
  } else if (status === "Inactive") {
    setStatus = "I";
  } else if (status === "Delete") {
    setStatus = "D";
  }
  return setStatus;
};

export const getLanguageList = () => {
  return languages.reduce((newList: SelectionOption[], item) => {
    if (item.active === true) {
      newList.push({
        label: item.name,
        value: item.name,
      });
    }
    return newList;
  }, []);
};

export const getTimeZonesList = () => {
  return timezones.reduce((newList: SelectionOption[], item) => {
    if (item.isActive === true) {
      newList.push({
        label: item.displayName,
        value: item.value,
      });
    }
    return newList;
  }, []);
};
