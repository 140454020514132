import InputTextField from "../../components/forms/InputTextField";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import FormButton from "../../components/forms/FormButton";
import { useAuth } from "../../hooks‬/Auth";
import AuthWithEmail from "../../components/forms/AuthWithEmail";
import {
  login,
  resendActivationLinkWithEmail,
  validateActivation,
} from "services/authRequests";
import { setAuth } from "helpers/AuthHelpers";
import { useTranslation } from "react-i18next";
import { useAuthValidationRules } from "helpers/validation-schema/AuthValidationRules";
import {
  ERROR_STATUS_KEY,
  INVALID_USER_EMAIL_OR_STATUS,
  MAX_NUMBER_OF_RETRIES,
  NOT_FOUND_USER_EMAIL,
} from "constant/errorCode";
import Button from "components/base/button/Button";
import LoaderSpinner from "components/base/LoaderSpinner";

const initialValues = {
  email: "",
  password: "",
};

const LoginPage = () => {
  const { t } = useTranslation();
  const validationRules = useAuthValidationRules();
  const { companyDetailFormDashboarding, fetchUserDetail } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [isActiveEmail, setIsActiveEmail] = useState(false);
  const [showEmailVerificationMsg, setShowEmailVerificationMsg] =
    useState(false);
  const [attempt, setAttempt] = useState(true);
  const [loading, setLoading] = useState(false);
  const [continueBtnLoader, setContinueBtnLoader] = useState(false);
  const [sendEmailLoader, setSendEmailLoader] = useState(false);
  const loginSchema = Yup.object().shape({
    email: validationRules.emailValidationSchema,
    password: validationRules.passwordValidationSchema,
  });
  const [isInitSendVerificationEmail, setIsInitSendVerificationEmail] =
    useState(false);
  const [countdown, setCountdown] = useState<number>(
    parseInt(sessionStorage.getItem("countdown") ?? "60")
  );
  let interval: NodeJS.Timeout;

  const sendVerificationEmailHandler = async () => {
    setSendEmailLoader(true);
    try {
      await resendActivationLinkWithEmail(formik.values.email)
        .then((response: any) => {
          if (response.data.status === ERROR_STATUS_KEY) {
            if (response.data.statusCode === MAX_NUMBER_OF_RETRIES) {
              setAttempt(false);
              formik.setStatus(response.data.statusDesc);
              setShowEmailVerificationMsg(false);
            }
          } else if (response.status === 200) {
            clearInterval(interval);
            setCountdown(60);
            sessionStorage.setItem("countdown", "60");
            setShowEmailVerificationMsg(false);
            setAttempt(true);
            setIsInitSendVerificationEmail(true);
            setSendEmailLoader(false);
          }
        })
        .catch((error) => {
          formik.setStatus(error.response.data.statusDesc);
          setSendEmailLoader(false);
        });
    } catch (error) {
      formik.setStatus(t("messages:INTERNAL.SERVER.ERROR"));
    }
  };

  const userActivationHandler = async (email: string) => {
    setContinueBtnLoader(true);
    try {
      await validateActivation(email)
        .then((response) => {
          if (response.data.status === ERROR_STATUS_KEY) {
            if (response.data.statusCode === INVALID_USER_EMAIL_OR_STATUS) {
              setShowEmailVerificationMsg(true);
              formik.setStatus("");
            } else if (response.data.statusCode === NOT_FOUND_USER_EMAIL) {
              setShowEmailVerificationMsg(false);
              formik.setStatus(response.data.statusDesc);
            } else {
              formik.setStatus("");
              formik.setErrors({
                ...formik.errors,
                email: response.data.userEmail,
              });
            }
            setContinueBtnLoader(false);
          } else if (response.status === 200) {
            setContinueBtnLoader(false);
            setShowEmailVerificationMsg(false);
            setIsActiveEmail(true);

            formik.setStatus("");
          }
        })
        .catch((error) => {
          formik.setStatus(t(error.response.data.statusDesc));
          setContinueBtnLoader(false);
        });
    } catch (error) {
      setContinueBtnLoader(false);
      formik.setStatus(t("messages:INTERNAL.SERVER.ERROR"));
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus }) => {
      setLoading(true);
      try {
        await login(values.email, values.password)
          .then((response) => {
            if (response.data.status === "ERROR") {
              setStatus(t("messages:AUTH.LOGIN.VALIDATION"));
            } else {
              setAuth({ authorization: response.headers.authorization });
              companyDetailFormDashboarding();
              fetchUserDetail();
            }
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
            setStatus(t("messages:AUTH.LOGIN.VALIDATION"));
          });
      } catch (error) {
        setLoading(false);
        formik.setStatus(t("messages:INTERNAL.SERVER.ERROR"));
      }
    },
  });

  useEffect(() => {
    // eslint-disable-next-line
    interval = setInterval(() => {
      setCountdown((prevCountdown) => Math.max(0, prevCountdown - 1));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    sessionStorage.setItem("countdown", countdown.toString());
  }, [countdown]);

  const handleEmailValidate = async () => {
    await formik.validateField("email");
    formik.setFieldTouched("email", true);
    if (!formik.errors.email) {
      userActivationHandler(formik.values.email);
      formik.setFieldTouched("password", false);
    }
  };

  function attemptMsg() {
    if (countdown <= 0) {
      if (sendEmailLoader) {
        return (
          <div className="margin-auto d-flex justify-content-center my-5">
            <LoaderSpinner />
          </div>
        );
      } else {
        return (
          <div className="text-center fs-3 my-5 mb-10">
            <p className="my-0"> {t("messages:ASK.TO.RESEND")}</p>{" "}
            <span
              className="text-l text-primary fw-bolder text-decoration-underline pointer"
              onClick={() => {
                sendVerificationEmailHandler();
              }}
              onKeyDown={() => {
                sendVerificationEmailHandler();
              }}
            >
              {t("RESEND")} {t("EMAIL")}
            </span>
          </div>
        );
      }
    } else {
      return (
        <div className="text-center fs-3 my-5 mb-10">
          {t("messages:RESEND.IT.IN")}{" "}
          <span className="text-danger fw-bold ">{countdown}</span>{" "}
          {t("SECOND")}
        </div>
      );
    }
  }

  return (
    <form className="form w-100 " onSubmit={formik.handleSubmit} noValidate>
      <div className="text-center mb-11">
        <h1 className="text-dark fw-bolder mb-3">{t("LOGIN")}</h1>
        <div className="text-gray-700 fw-semibold fs-6">
          {t("messages:AUTH.DESCRIPTION")}
        </div>
      </div>
      {formik.status && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text text-center font-weight-bold">
            {formik.status}
          </div>
        </div>
      )}
      {showEmailVerificationMsg &&
        !formik.status &&
        (sendEmailLoader ? (
          <div className="margin-auto d-flex justify-content-center my-5">
            <LoaderSpinner />
          </div>
        ) : (
          <div className="mb-lg-10 alert alert-danger">
            <div className="alert-text text-center font-weight-bold">
              {t("messages:SEND.ACTIVATION.EMAIL.MSG")}{" "}
              <span
                className="text-l text-primary fw-bolder text-decoration-underline pointer"
                onClick={() => {
                  sessionStorage.setItem("countdown", "60");
                  sendVerificationEmailHandler();
                }}
                onKeyDown={() => {
                  sessionStorage.setItem("countdown", "60");
                  sendVerificationEmailHandler();
                }}
              >
                {t("SEND")} {t("EMAIL")}
              </span>
            </div>
          </div>
        ))}
      {attempt &&
        !showEmailVerificationMsg &&
        !formik.status &&
        isInitSendVerificationEmail &&
        attemptMsg()}
      <InputTextField
        type="text"
        placeholder={t("EMAIL")}
        id="email"
        name="email"
        label={t("EMAIL")}
        isAutoFocus={!isActiveEmail}
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            handleEmailValidate();
            event.preventDefault();
          }
        }}
        isDisable={isActiveEmail}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
      />
      {isActiveEmail ? (
        <>
          <div className="position-relative">
            <div
              className="position-absolute text-primary fw-bold pointer"
              style={{ right: "0px", top: "-10px" }}
              onClick={() => setIsActiveEmail(false)}
              onKeyDown={() => setIsActiveEmail(false)}
            >
              {t("EDIT")}
            </div>
          </div>
          <InputTextField
            type={showPassword ? "text" : "password"}
            placeholder={t("PASSWORD")}
            id="password"
            name="password"
            label={t("PASSWORD")}
            isAutoFocus={isActiveEmail}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <div className="d-flex justify-content-between mb-8">
            <div className="cursor-pointer">
              <input
                id="ShowPassword"
                className="mx-1"
                type="checkbox"
                onChange={() => {
                  setShowPassword(!showPassword);
                }}
              />
              <label htmlFor="ShowPassword" className="mx-1">
                {t("SHOW.PASSWORD")}
              </label>
            </div>
            <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold ">
              <Link to="/forgot-password">{t("FORGOT.PASSWORD")}?</Link>
            </div>
          </div>{" "}
          <FormButton
            btnLabel={t("LOGIN")}
            formik={formik}
            isBtnDisabled={formik.isSubmitting || !formik.isValid || loading}
            loading={loading}
          />{" "}
        </>
      ) : (
        <div className="d-grid my-4">
          <Button
            type="button"
            disabled={continueBtnLoader}
            onClick={() => handleEmailValidate()}
          >
            {continueBtnLoader ? (
              <span className="indicator-progress" style={{ display: "block" }}>
                {t("WAIT")}...{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            ) : (
              <>{t("CONTINUE")}</>
            )}
          </Button>
        </div>
      )}
      <div className="separator text-dark border-dark separator-content mt-14">
        <span className="fw-semibold fs-7">{t("OR")}</span>
      </div>
      <div className="d-flex justify-content-center gap-5 my-10">
        <AuthWithEmail img_url="https://steelbluemedia.com/wp-content/uploads/2019/06/new-google-favicon-512.png" />
        {/* <AuthWithEmail img_url="https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Microsoft_logo.svg/2048px-Microsoft_logo.svg.png" />
        <AuthWithEmail img_url="https://www.shutterstock.com/image-illustration/lahore-pakistan-may-11-2023-260nw-2301489841.jpg" /> */}
      </div>
      <div className="text-gray-500 text-center fw-semibold fs-6">
        {t("messages:NOT.ACCOUNT")}?{" "}
        <Link to="/registration">{t("SIGN.UP")}</Link>
      </div>
    </form>
  );
};
export default LoginPage;
