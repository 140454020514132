import React, { FC, useEffect, useRef, useState } from "react";
import Button from "../../components/base/button/Button";
import { UserDetails } from "helpers/models/dashboard";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import FormButton from "components/forms/FormButton";
import InputTextField from "components/forms/InputTextField";
import * as Yup from "yup";
import SearchableDropdown from "components/forms/SearchableDropdown";
import {
  useAuthValidationRules,
  useDropdownValidationRules,
} from "helpers/validation-schema/AuthValidationRules";
import { updateUser } from "services/userManagementAPIs";
import { useLocationContext } from "hooks‬/LocationContext";
import { areJSONsEqual } from "helpers/common";
import CardHeader from "components/cards/CardHeader";
import { statusGetter } from "helpers/common/genericFunction";

interface Props {
  userDetails: UserDetails | undefined;
  userHandler: () => void;
}
interface City {
  guid: string;
  displayName: string;
  state: {
    guid: string;
    displayName: string;
    country: {
      guid: string;
      displayName: string;
    };
  };
}
export interface InitUserDetails {
  guid: string;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  email: string;
  address1: string;
  address2: string;
  status: string;
  pinCode: string;
  city: City;
  systemAdmin: boolean;
}

const initialValues: InitUserDetails = {
  guid: "",
  firstName: "",
  lastName: "",
  mobileNumber: "",
  email: "",
  address1: "",
  address2: "",
  status: "",
  pinCode: "",
  city: {
    guid: "",
    displayName: "",
    state: {
      guid: "",
      displayName: "",
      country: {
        guid: "",
        displayName: "",
      },
    },
  },
  systemAdmin: false,
};
const ProfileOverview: FC<Props> = ({ userDetails, userHandler }) => {
  const { t } = useTranslation();
  const {
    fetchCountries,
    countries,
    fetchStates,
    states,
    fetchCities,
    cities,
    setStates,
    setCities,
  } = useLocationContext();
  const validationRules = useAuthValidationRules();
  const [loading, setLoading] = useState(false);
  const [isDisable, setIsDisable] = useState<boolean>(true);
  const didRequest = useRef(false);

  const stateHandler = async (guid: string | undefined) => {
    formik.setFieldValue("city.state.guid", "");
    formik.setFieldValue("city.guid", "");
    fetchStates(guid);
  };

  const cityHandler = async (guid: string | undefined) => {
    formik.setFieldValue("city.guid", "");
    fetchCities(guid);
  };

  useEffect(() => {
    if (userDetails) formik.setValues({ ...initialValues, ...userDetails });
    return () => {
      setStates([]);
      setCities([]);
    };
    // eslint-disable-next-line
  }, [userDetails]);

  useEffect(() => {
    function setData() {
      formik.setValues({ ...initialValues, ...userDetails }).then(() => {
        setIsDisable(false);
        setLoading(false);
      });
      didRequest.current = true;
    }

    if (
      (countries.length > 0 &&
        states.length > 0 &&
        cities.length > 0 &&
        userDetails &&
        !didRequest.current) ||
      (userDetails &&
        !userDetails.city &&
        countries.length &&
        !didRequest.current)
    ) {
      setData();
    }
    // eslint-disable-next-line
  }, [countries, states, cities]);

  const editableData = () => {
    setLoading(true);
    fetchCountries();
    if (userDetails?.city) {
      stateHandler(userDetails.city.state.country.guid);
      cityHandler(userDetails.city.state.guid);
      Promise.all([fetchCountries, stateHandler, cityHandler]).then(() => {
        didRequest.current = false;
      });
    } else {
      Promise.all([fetchCountries]).then(() => {
        didRequest.current = false;
      });
    }
  };

  const cancelToUpdate = () => {
    if (userDetails) formik.setValues({ ...initialValues, ...userDetails });
    setIsDisable(true);
  };

  const validationSchema = Yup.object().shape({
    email: validationRules.emailValidationSchema,
    address1: validationRules.address1ValidationSchema,
    address2: validationRules.address2ValidationSchema,
    firstName: validationRules.nameValidationSchema,
    lastName: validationRules.nameValidationSchema,
    mobileNumber: validationRules.contactNumberValidationSchema,
    pinCode: validationRules.pinCodeValidationSchema,
    city: Yup.object().shape({
      guid: useDropdownValidationRules(cities),
      state: Yup.object().shape({
        guid: useDropdownValidationRules(states),
        country: Yup.object().shape({
          guid: useDropdownValidationRules(countries),
        }),
      }),
    }),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      let isUpdated = true;
      if (userDetails) {
        isUpdated = !areJSONsEqual(values, userDetails);
      }
      let userStatus = statusGetter(values.status);
      try {
        if (isUpdated) {
          await updateUser({
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            mobileNumber: values.mobileNumber,
            address1: values.address1,
            address2: values.address2,
            pinCode: values.pinCode,
            cityGuid: values.city?.guid,
            status: userStatus,
          }).then(() => {
            setLoading(false);
            setIsDisable(true);
            userHandler();

            setSubmitting(false);
          });
        } else {
          setLoading(false);
          setIsDisable(true);
          setSubmitting(false);
        }
      } catch (error) {
        console.error(error);
      }
    },
  });

  return (
    <div className="card p-10  my-5">
      <form onSubmit={formik.handleSubmit} noValidate>
        <CardHeader>
          <h3 className="my-4">{t("USER.PROFILE.DETAILS")}</h3>
          {isDisable ? (
            <div className="m-4">
              <Button
                classes="btn-sm rounded-pill"
                onClick={(event) => {
                  event.preventDefault();
                  editableData();
                }}
                loading={loading}
              >
                <span className=" text-uppercase"> {t("EDIT")}</span>
              </Button>
            </div>
          ) : (
            <div className="d-flex gap-2">
              <FormButton
                btnLabel={t("SAVE")}
                classes="btn-sm rounded-pill my-0"
                formik={formik}
                isBtnDisabled={formik.isSubmitting || !formik.isValid}
                loading={loading}
              />{" "}
              <div className="  my-4">
                <Button
                  classes="btn-sm rounded-pill btn-danger"
                  onClick={() => {
                    cancelToUpdate();
                  }}
                  disabled={loading}
                >
                  <span className=" text-uppercase"> {t("CANCEL")}</span>
                </Button>
              </div>
            </div>
          )}
        </CardHeader>
        <div className="row my-4 justify-content-between fs-6  ">
          <div className="col-12 col-md-6 col-xxl-5">
            <div className="row">
              <div className="col-4 mb-4 fs-16 text-muted d-flex   align-items-center">
                {t("FIRST.NAME")}
              </div>
              <div className="col-8">
                <InputTextField
                  isDisable={isDisable}
                  type="text"
                  placeholder={t("FIRST.NAME")}
                  id="firstName"
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-4 mb-4 fs-16 text-muted d-flex   align-items-center">
                {t("LAST.NAME")}
              </div>
              <div className="col-8">
                <InputTextField
                  isDisable={isDisable}
                  type="text"
                  placeholder={t("LAST.NAME")}
                  id="lastName"
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-4 mb-4 fs-16 text-muted d-flex   align-items-center">
                {t("ADDRESS1")}
              </div>
              <div className="col-8">
                <InputTextField
                  isDisable={isDisable}
                  type="text"
                  placeholder={t("ADDRESS1")}
                  id="address1"
                  name="address1"
                  value={formik.values.address1 || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.address1 && Boolean(formik.errors.address1)
                  }
                  helperText={formik.touched.address1 && formik.errors.address1}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-4 mb-4 fs-16 text-muted d-flex   align-items-center">
                {t("ADDRESS2")}
              </div>
              <div className="col-8">
                <InputTextField
                  isDisable={isDisable}
                  type="text"
                  placeholder={t("ADDRESS2")}
                  id="address2"
                  name="address2"
                  value={formik.values.address2 || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.address2 && Boolean(formik.errors.address2)
                  }
                  helperText={formik.touched.address2 && formik.errors.address2}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-4 mb-4 fs-16 text-muted d-flex   align-items-center">
                {t("EMAIL")}
              </div>
              <div className="col-8">
                <InputTextField
                  isDisable={true}
                  type="text"
                  placeholder={t("EMAIL")}
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-4 mb-4 fs-16 text-muted d-flex   align-items-center">
                {t("CONTACT.NUMBER")}
              </div>
              <div className="col-8">
                <InputTextField
                  type="text"
                  placeholder={t("CONTACT.NUMBER")}
                  id="mobileNumber"
                  name="mobileNumber"
                  value={formik.values.mobileNumber || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.mobileNumber &&
                    Boolean(formik.errors.mobileNumber)
                  }
                  helperText={
                    formik.touched.mobileNumber && formik.errors.mobileNumber
                  }
                  isDisable={isDisable}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-xxl-5">
            <div className="row">
              <div className="col-4 mb-4 fs-16 text-muted d-flex   align-items-center">
                {t("COUNTRY")}
              </div>
              <div className="col-8">
                {isDisable ? (
                  <InputTextField
                    isDisable={isDisable}
                    type="text"
                    placeholder={t("COUNTRY")}
                    id="city.state.country.guid"
                    name="city.state.country.guid"
                    value={formik.values.city?.state.country.displayName || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                ) : (
                  <SearchableDropdown
                    isDisable={isDisable}
                    placeholder={t("COUNTRY")}
                    name="city.state.country.guid"
                    value={formik.values.city?.state.country.guid || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    selectionOptions={countries}
                    getSelectedOption={stateHandler}
                    error={
                      formik.touched.city?.state?.country?.guid &&
                      Boolean(formik.errors.city?.state?.country?.guid)
                    }
                    helperText={
                      formik.touched.city?.state?.country?.guid &&
                      formik.errors.city?.state?.country?.guid
                    }
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-4 mb-4 fs-16 text-muted d-flex   align-items-center">
                {t("STATE")}
              </div>
              <div className="col-8">
                {isDisable ? (
                  <InputTextField
                    isDisable={isDisable}
                    type="text"
                    placeholder={t("STATE")}
                    id="city.state.guid"
                    name="city.state.guid"
                    value={formik.values.city.state.displayName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                ) : (
                  <SearchableDropdown
                    placeholder={t("STATE")}
                    name="city.state.guid"
                    value={formik.values.city.state.guid || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    selectionOptions={states}
                    getSelectedOption={cityHandler}
                    error={
                      formik.touched.city?.state?.guid &&
                      Boolean(formik.errors.city?.state?.guid)
                    }
                    helperText={
                      formik.touched.city?.state?.guid &&
                      formik.errors.city?.state?.guid
                    }
                    isDisable={isDisable}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-4 mb-4 fs-16 text-muted d-flex   align-items-center">
                {t("CITY")}
              </div>
              <div className="col-8">
                {isDisable ? (
                  <InputTextField
                    isDisable={isDisable}
                    type="text"
                    placeholder={t("CITY")}
                    id="city.guid"
                    name="city.guid"
                    value={formik.values.city.displayName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                ) : (
                  <SearchableDropdown
                    isDisable={isDisable}
                    placeholder={t("CITY")}
                    name="city.guid"
                    value={formik.values.city.guid || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    selectionOptions={cities}
                    error={
                      formik.touched.city?.guid &&
                      Boolean(formik.errors.city?.guid)
                    }
                    helperText={
                      formik.touched.city?.guid && formik.errors.city?.guid
                    }
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-4 mb-4 fs-16 text-muted d-flex   align-items-center">
                {t("PINCODE")}
              </div>
              <div className="col-8">
                <InputTextField
                  isDisable={isDisable}
                  type="text"
                  placeholder={t("PINCODE")}
                  id="pinCode"
                  name="pinCode"
                  value={formik.values.pinCode || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.pinCode && Boolean(formik.errors.pinCode)
                  }
                  helperText={formik.touched.pinCode && formik.errors.pinCode}
                />
              </div>
            </div>
          </div>
        </div>{" "}
      </form>
    </div>
  );
};

export default ProfileOverview;
