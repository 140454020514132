import axios, { AxiosResponse } from "axios";
import apiInterceptor from "./apiInterceptor";
import {
  CompanyDetails,
  ResetPassword,
  Signup,
  ComapnyDomain,
  SelectionOptionResponse,
} from "helpers/models/auth_models";
import {
  ENDPOINT_CITY,
  ENDPOINT_USER_ACTIVE,
  ENDPOINT_COUNTRY,
  ENDPOINT_STATE,
  ENDPOINT_COMPANY_DOMAIN,
  ENDPOINT_SIGNUP,
  ENDPOINT_RESEND_EMAIL,
  ENDPOINT_LOGIN,
  ENDPOINT_VALIDATE_ACTIVATION,
  ENDPOINT_RESET_PASSWORD_TOKEN,
  ENDPOINT_COMPANY_DETAILS,
  ENDPOINT_CREATE_COMPANY,
} from "constant/apiConstants";
const { REACT_APP_URL } = process.env;
const axiosConfig = axios.create({
  baseURL: REACT_APP_URL ?? "",
});

export async function getCountries(): Promise<AxiosResponse> {
  const response: AxiosResponse<SelectionOptionResponse> =
    await apiInterceptor.get<SelectionOptionResponse>(
      `${ENDPOINT_COUNTRY}?limit=300`
    );
  return response;
}

export async function getStates(
  guid: string | undefined
): Promise<AxiosResponse> {
  const response: AxiosResponse<SelectionOptionResponse> =
    await apiInterceptor.get<SelectionOptionResponse>(
      `${ENDPOINT_STATE}?limit=250&country_guid=${guid}`
    );
  return response;
}

export async function getCity(
  guid: string | undefined
): Promise<AxiosResponse> {
  const response: AxiosResponse<SelectionOptionResponse> =
    await apiInterceptor.get<SelectionOptionResponse>(
      `${ENDPOINT_CITY}?limit=3000&state_guid=${guid}`
    );
  return response;
}

export async function getCompanyDomain(): Promise<AxiosResponse> {
  const response: AxiosResponse<ComapnyDomain[]> = await apiInterceptor.get<
    ComapnyDomain[]
  >(`${ENDPOINT_COMPANY_DOMAIN}?order=ASC&sortBy=ObjId&limit=30`);

  return response;
}

export function login(email: string, password: string) {
  return axiosConfig.post<any>(`${ENDPOINT_LOGIN}`, {
    email,
    password,
  });
}

export function signup(signup: Signup) {
  return axiosConfig.post<any>(`${ENDPOINT_SIGNUP}`, signup);
}

export function resendActivationLinkWithEmail(email: string | undefined) {
  return axiosConfig.get<any>(`${ENDPOINT_RESEND_EMAIL}?user_email=${email}`);
}

export function resendActivationLinkWithToken(
  token: string | undefined | null
) {
  return axiosConfig.get<any>(
    `${ENDPOINT_RESEND_EMAIL}?verification_token=${token}`
  );
}

export function userActivation(token: string | null) {
  return axiosConfig.get<any>(`${ENDPOINT_USER_ACTIVE}?token=${token}`);
}
export function validateActivation(userEmail: string) {
  return axiosConfig.post(`${ENDPOINT_VALIDATE_ACTIVATION}`, {
    userEmail: userEmail,
  });
}

export function generateResetPasswordToken(userEmail: {
  userEmail: string | undefined;
}) {
  return axiosConfig.post(`${ENDPOINT_RESET_PASSWORD_TOKEN}`, userEmail);
}

export function validateForgotPasswordToken(token: string | null) {
  return axiosConfig.get(`${ENDPOINT_RESET_PASSWORD_TOKEN}/${token}/validate`);
}

export function resetPassword(data: ResetPassword) {
  return axiosConfig.put(`${ENDPOINT_RESET_PASSWORD_TOKEN}/${data.token}`, {
    password: data.password,
  });
}

export function getCompanyDetails() {
  return apiInterceptor.get(`${ENDPOINT_COMPANY_DETAILS}`);
}
export function createCompany(companyDetails: CompanyDetails) {
  return apiInterceptor.post(`${ENDPOINT_CREATE_COMPANY}`, companyDetails);
}

export function updateCompany(companyDetails: CompanyDetails) {
  return apiInterceptor.put(`${ENDPOINT_CREATE_COMPANY}`, companyDetails);
}
