import { ACTIVATION_LINK_EXPIRED, SUCCESS_CODE } from "constant/errorCode";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  resendActivationLinkWithEmail,
  resendActivationLinkWithToken,
} from "services/authRequests";
import CommonActivationUI, { ActivationInfoUI } from "./CommonActivationUI";

interface DataProps {
  email?: string;
  token?: string;
}

const SendActivationLink: React.FC = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { data } = (state as { data?: DataProps }) || {};
  const [userData, setUserData] = useState<DataProps>({});
  const [countdown, setCountdown] = useState<number>(
    parseInt(sessionStorage.getItem("countdown") ?? "60")
  );
  const [attempt, setAttempt] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  let interval: NodeJS.Timeout;

  const handleResend = () => {
    // try {
    if (userData.email) {
      resendActivationLinkWithEmail(userData.email)
        .then(() => {
          clearInterval(interval);
          setCountdown(60);
        })
        .catch((error) => {
          if (error.response.data.statusCode === ACTIVATION_LINK_EXPIRED) {
            setAttempt(false);
            setErrorMsg(error.response.data.statusDesc);
          }
        });
    }
    if (userData.token) {
      resendActivationLinkWithToken(userData.token)
        .then((response: any) => {
          if (response.status === SUCCESS_CODE) {
            clearInterval(interval);
            setCountdown(60);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    // } catch (error) {
    //   console.error(error);
    // }
  };
  /*
This is the code for refresh page, if email is active it will redirect to login page
when in future we will need we will do un-comment out
  const validateAfterRefresh = async (email: string | undefined) => {
    try {
      await validateActivation(email)
        .then((response) => {
          if (response.status === 200) {
            navigate("/", { replace: true });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  };*/

  useEffect(() => {
    setUserData(data ?? {});

    // eslint-disable-next-line
    interval = setInterval(() => {
      setCountdown((prevCountdown) => Math.max(0, prevCountdown - 1));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    sessionStorage.setItem("countdown", countdown.toString());
  }, [countdown]);

  useEffect(() => {
    if (!userData.email) {
      navigate("/login");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="n_one_login_signup_form"
    >
      <div className="text-center mb-11">
        <ActivationInfoUI title={t("messages:VERIFY.EMAIL")} />
        <div className="text-gray-700 fw-semibold fs-4">
          {t("messages:HAVE.SENT.AN.EMAIL")}{" "}
          <span className="text-primary fw-bold">{data?.email}</span>{" "}
          {t("messages:VERIFY.YOUR.EMAIL.ADDRESS")}
        </div>
      </div>
      <CommonActivationUI
        attempt={attempt}
        countdown={countdown}
        errorMsg={errorMsg}
        handleResend={() => handleResend()}
      />
    </form>
  );
};

export default SendActivationLink;
