import React from "react";

const AccessButtonIcon = ({ isBought }: { isBought: boolean }) => {
  return (
    <div
      className="d-flex justify-content-end align-items-end ml-5 "
      style={{ position: "absolute", bottom: "5px", right: "10px" }}
    >
      <button
        className={`btn btn-primary  btn-sm rounded-circle ${
          !isBought && "disabled"
        }`}
        style={{ width: "58px", height: "58px" }}
      >
        <i
          className="fa-solid fa-location-arrow bg-primary-text"
          style={{ fontSize: "20px" }}
        ></i>
      </button>
    </div>
  );
};

export default AccessButtonIcon;
