export const VERSION: string = "/v1";
export const AUTH: string = "/auth";

export const ENDPOINT_LOGIN = AUTH + "/login";
export const ENDPOINT_COUNTRY: string = VERSION + "/country";
export const ENDPOINT_STATE: string = VERSION + "/state";
export const ENDPOINT_CITY: string = VERSION + "/city";
export const ENDPOINT_COMPANY_DOMAIN: string = VERSION + "/company/domain";

export const ENDPOINT_SIGNUP = "/subscription" + VERSION + "/signup";

export const ENDPOINT_RESEND_EMAIL =
  "/subscription" + VERSION + "/user/activation/resend";

export const ENDPOINT_USER_ACTIVE = VERSION + "/user/activate";
export const ENDPOINT_VALIDATE_ACTIVATION =
  "/subscription" + VERSION + "/user/activation";
export const ENDPOINT_RESET_PASSWORD_TOKEN = "/auth/reset_password";
export const ENDPOINT_COMPANY_DETAILS =
  "/subscription" + VERSION + "/company/me";

export const ENDPOINT_CREATE_COMPANY = "/subscription" + VERSION + "/company";

export const ENDPOINT_PRODUCTS = "/subscription" + VERSION + "/user/products";
export const ENDPOINT_ALL_PRODUCTS =
  "/subscription" + VERSION + "/product_type";
export const ENDPOINT_SERVICES = "/subscription" + VERSION + "/services";
export const ENDPOINT_OFFERED_SERVICES =
  "/subscription" + VERSION + "/company/offered_services";
export const ENDPOINT_LEGAL_DETAILS_FIELD =
  "/subscription" + VERSION + "/legal_details/country";

export const ENDPOINT_COMPANY_LEGAL_DETAILS =
  "/subscription" + VERSION + "/legal_details/company";

export const ENDPOINT_COMPANY_PREFERENCES =
  "/subscription" + VERSION + "/company/preference/me";
export const ENDPOINT_COMPANY_PREFERENCES_SYSTEM_MODE =
  "/subscription" + VERSION + "/company/preference/system_mode/me";

export const ENDPOINT_COMPANY_BRANCH =
  "/subscription" + VERSION + "/company/branch";
export const ENDPOINT_USER = "/subscription" + VERSION + "/user/me";
export const ENDPOINT_ALL_USER = "/subscription" + VERSION + "/user";
export const ENDPOINT_USER_ROLE = AUTH + "/user_role";
export const en = "/subscription" + VERSION + "/user";
export const ENDPOINT_USER_PREFERENCES =
  "/subscription" + VERSION + "/user/preference/me";

export const ENDPOINT_USER_PREFERENCES_SYSTEM_MODE =
  "/subscription" + VERSION + "/user/preference/system_mode/me";
export const RESEND_USER_INVITATION = "/subscription" + VERSION + "/user";
export const ENDPOINT_ROLE_PRIVILEGE = AUTH + "/user_role/privilege";
export const ENDPOINT_UPDATE_ROLE_PRIVILEGE = AUTH + "/user_role";
export const ENDPOINT_ASSIGNED_SPECIFIC_ROLE =
  "/subscription" + VERSION + "/user_role";
export const ENDPOINT_REBRANDING = "/subscription" + VERSION + "/rebranding/me";
export const ENDPOINT_CREATE_REBRANDING =
  "/subscription" + VERSION + "/rebranding";
