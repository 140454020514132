import { Navigate, Route, Routes } from "react-router-dom";
import LoginPage from "../pages/auth/LoginPage";
import AuthLayout from "../layout/AuthLayout";
import { Registration } from "../pages/auth/Registration";
import SendActivationLink from "pages/auth/SendActivationLink";
import ForgotPassword from "pages/auth/forgot-password";
import SendForgotPasswordLink from "pages/auth/forgot-password/SendForgotPasswordLink";
import SetNewPassword from "pages/auth/forgot-password/SetNewPassword";
import NotFound from "pages/error-page/NotFound";
import EmailVerified from "pages/auth/EmailVerified";
import { privateRoutesArray } from "constant/route/privateRoutes";
import { authRoutes } from "constant/route/authRoutes";

const AuthRoutes = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path={authRoutes.login} element={<LoginPage />} />
      <Route path={authRoutes.registration} element={<Registration />} />
      <Route index element={<Navigate to={authRoutes.login} />} />
      <Route
        path={authRoutes.emailVerification}
        element={<SendActivationLink />}
      />
      <Route path={authRoutes.forgotPassword} element={<ForgotPassword />} />
      <Route
        path={authRoutes.forgotLinkSent}
        element={<SendForgotPasswordLink />}
      />
      <Route path={authRoutes.resetPassword} element={<SetNewPassword />} />
      <Route path={authRoutes.emailVerified} element={<EmailVerified />} />
    </Route>

    {privateRoutesArray.map((path) => (
      <Route
        path={path}
        element={<Navigate to={authRoutes.login} />}
        key={path}
      />
    ))}
    <Route path="*" element={<NotFound />} />
  </Routes>
);

export { AuthRoutes };
