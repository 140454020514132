const LoaderSpinner = ({ size = "1.5rem" }: { size?: string }) => {
  return (
    <span
      className="spinner-border spinner-border-sm align-middle ms-2"
      style={{ width: size, height: size }}
    ></span>
  );
};

export default LoaderSpinner;
