import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
  ReactNode,
  useMemo,
} from "react";

import * as authHelper from "../helpers/AuthHelpers";
import { AuthModel } from "../helpers/models/auth_models";
import { getCompanyDetails } from "services/authRequests";
import { GetCompanyDetails, UserDetails } from "helpers/models/dashboard";
import { getUser } from "services/userManagementAPIs";

type WithChildren = {
  children?: ReactNode;
};
type AuthContextProps = {
  auth: AuthModel | undefined;
  saveAuth: (auth: AuthModel | undefined) => void;
  isLogedIn: boolean | undefined;
  setIsLogedIn: Dispatch<SetStateAction<boolean | undefined>>;
  currentCompany: GetCompanyDetails;
  setCurrentCompany: Dispatch<SetStateAction<GetCompanyDetails>>;
  logout: () => void;
  companyDetailFormDashboarding: () => void;
  fetchUserDetail: () => void;
  userDetails: UserDetails | undefined;
};

const initCompanyDetatils = {
  address1: "",
  address2: "",
  cityGuid: "",
  cityName: "",
  companyDetailsGuid: "",
  companyDomainGuid: "",
  companyDomainName: "",
  contactNumber: "",
  countryGuid: "",
  countryName: "",
  email: "",
  isReseller: "",
  name: "",
  pinCode: "",
  stateGuid: "",
  stateName: "",
  websiteUrl: "",
};

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  isLogedIn: undefined,
  setIsLogedIn: () => {},
  currentCompany: initCompanyDetatils,
  setCurrentCompany: () => {},
  logout: () => {},
  companyDetailFormDashboarding: () => {},
  fetchUserDetail: () => {},
  userDetails: undefined,
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth());
  const [isLogedIn, setIsLogedIn] = useState<boolean | undefined>();
  const [currentCompany, setCurrentCompany] =
    useState<GetCompanyDetails>(initCompanyDetatils);
  const [userDetails, setUserDetails] = useState<UserDetails | undefined>();

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth);
    if (auth) {
      authHelper.setAuth(auth);
    } else {
      authHelper.removeAuth();
    }
  };

  const logout = () => {
    saveAuth(undefined);
    setIsLogedIn(false);
  };

  const companyDetailFormDashboarding = async () => {
    try {
      await getCompanyDetails()
        .then((res: any) => {
          setCurrentCompany(res.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  const fetchUserDetail = async () => {
    try {
      await getUser()
        .then((response: any) => {
          setUserDetails(response.data.data);
          setIsLogedIn(true);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const contextValue = useMemo(
    () => ({
      auth,
      saveAuth,
      isLogedIn,
      setIsLogedIn,
      logout,
      currentCompany,
      setCurrentCompany,
      companyDetailFormDashboarding,
      fetchUserDetail,
      userDetails,
    }),
    // eslint-disable-next-line
    [auth, isLogedIn, currentCompany, setCurrentCompany, userDetails]
  );
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

const AuthInit: FC<WithChildren> = ({ children }) => {
  const {
    auth,
    logout,
    setIsLogedIn,
    fetchUserDetail,
    companyDetailFormDashboarding,
  } = useAuth();
  const didRequest = useRef(false);
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          if (apiToken) {
            companyDetailFormDashboarding();
            fetchUserDetail();
          } else {
            setIsLogedIn(false);
          }
        }
      } catch (error) {
        console.error(error);
        if (!didRequest.current) {
          logout();
        }
      }
      return () => (didRequest.current = true);
    };

    if (auth?.authorization) {
      requestUser(auth.authorization);
    } else {
      logout();
    }
    // eslint-disable-next-line
  }, []);

  return <>{children}</>;
};

export { AuthProvider, AuthInit, useAuth };
