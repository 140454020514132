import { FC } from "react";
interface AuthWithEmailProps {
  img_url: string;
}
const AuthWithEmail: FC<AuthWithEmailProps> = ({ img_url }) => {
  return (
    <div className="d-flex justify-content-center">
      <div className="border border-1 border-primary  rounded-circle w-50px h-50px d-flex justify-content-center align-items-center">
        <img alt="Logo" src={img_url} className="h-35px" />
      </div>
    </div>
  );
};

export default AuthWithEmail;
